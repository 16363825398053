
import React, { useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'

import CarouselService from '../../service/admin/carousel/CarouselService'


const Carousel = ({show, onHide}) => {
    
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Редактор карусели
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <CarouselService /> 

            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-danger" onClick={onHide}>Закрыть</Button>                
            </Modal.Footer>
        </Modal>
    )
}

export default Carousel
 