//

export const Chinese = {
    // registration page
    registr_title: '登记注册',
    registr_surname: '姓:',
    registr_surname_placeholder: '输入您的姓氏',
    registr_name: '姓名:',
    registr_name_placeholder: '输入名称',
    registr_patronymic: '父系:(如有)',
    registr_patronymic_placeholder: '输入你的父系',
    registr_telephone: '电话:',
    registr_telephone_placeholder: '输入您的电话号码',
    registr_postal_address: '邮寄地址:',
    registr_address: '地址:',
    registr_address_placeholder: '输入地址',
    registr_email: '电邮:',
    registr_email_placeholder: '输入电子邮件',
    registr_password: '密码:',
    registr_password_placeholder: '输入密码',
    registr_legal_entity_title: '法律实体的注册',
    registr_legal_entity: '法律实体',
    registr_company_name: '公司名称:',
    registr_company_name_placeholder: '输入公司名称',
    registr_itn: 'INN(纳税人识别号码):',
    registr_itn_placeholder: '进入客栈',
    registr_kpp: 'KPP（声明原因代码）:',
    registr_kpp_placeholder: '输入注册原因代码',
    registr_ogrn: 'OGRN（主要国家注册号）:',
    registr_ogrn_placeholder: '输入主州注册号',
    registr_okved: 'OKVED（经济活动的全俄分类器）:',
    registr_okved_placeholder: '进入OKVED',
    registr_legal_address: '法定地址:',
    registr_legal_address_placeholder: '输入法定地址',
    registr_bank_name: '银行名称:',
    registr_bank_name_placeholder: '输入银行名称',
    registr_bik: 'BIC(银行识别码):',
    registr_bik_placeholder: '进入BIC',
    registr_cor_accaunt: '代理帐户:',
    registr_cor_accaunt_placeholder: '输入通讯员帐户',
    registr_payment_accaunt: '付款户口:',
    registr_payment_accaunt_placeholder: '输入您的支票帐户',
    registr_post: '职位:',
    registr_post_placeholder: '进入职位',
    registr_have_account: '你有帐户吗？',
    registr_enter: '进来！',
    // login page
    login_title: '授权书',
    login_email: '电邮或登入:',
    login_password: '密码:',
    login_forgot_password: '忘记密码？!',
    login_confirm: '登录确认。',
    login_no_accaunt: '没有帐户？',
    login_register: '登记！',
    login_enter: '进入',
    // consts_name
    consts_name: `有限责任公司"LEIDTOGI"`,
    consts_sity: '库尔斯克',
    consts_address: '库尔斯克,索洛维纳亚str.,51,办公室25',
    consts_address_full: '305044,库尔斯克,索洛维纳亚str.,51,办公室25',
    consts_address_full_moscow: '125438，莫斯科，2nd Likhachevsky lane，7',
    consts_time_to_work: '星期一至五:09:00-18:00',
    consts_time_to_weekend: '星期六-日：休息日',
    // header
    header_slogan: '与我们一起建设，节省时间',
    header_ap: '署长', // директор
    header_mp: '经理小组', // Группа менеджеров
    header_lk: '居室', // комната
    header_pers_acc: '个人帐户',
    header_exit: '退出',
    header_auth: '授权书',
    // pages
    page_shop: '产品中心',
    page_dealer: '经销商',
    page_payment: '付款',
    page_specials: '折扣优惠',
    page_delivery: '派递服务',
    page_support: '支援服务',
    page_news: '新闻',
    page_catalogs: '目录',    
    page_contacts: '联络人',
    page_about_us: '关于公司',
    page_returns_policy: '报税表条款',
    page_warranty: '保修和服务',
    page_privacy_policy: '私隐政策',
    page_terms_of_use: '用户协议',
    page_presentation: '陈述报告цыф',
    // footer
    footer_inform: '资料',
    footer_web: '我们在社交网络',
    footer_web_tg: '我们在电报',
    footer_web_vk: '我们在VKontakte',
    footer_contacts: '联络人',
    footer_copyright: ' 有限责任公司"LeidTogi"',
}
