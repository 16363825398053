import React from 'react'
import HtmlReactParser from 'html-react-parser'
// eslint-disable-next-line
import { MAIL, ADDRESS, PHONE_ONE, TIME_TO_WORK } from '../../utils/consts'
import InfoPage from './InfoPage'
import './Warranty.css'


const Warranty = () => {
    return (
        <InfoPage>
            <div className="Warranty">

                <header>Гарантия и сервисы!</header>

                <label>Гарантийное обслуживание и ремонт</label>

                <p>Гарантийный срок исчисляется со дня ввода изделия в эксплуатацию и зависит от вида оборудования или продукции. Конкретные гарантийные сроки, а также правила эксплуатации и технические характеристики указаны в паспортах на изделия. Все изделия до продажи проходят тестовые испытания под нагрузкой, превышающей номинальную.</p>
                <ul>
                    <li>Гарантийный ремонт производится в собственном сервисном центре компании.</li>
                    <li>Запчасти в наличии на складе.</li>
                    <li>Специалисты сервиса компании оперативно проконсультируют по вопросам ремонта и эксплуатации оборудования и ответят на ваши вопросы.</li>
                    <li>Служба сервиса позаботится о поставленном оборудовании, как во время гарантийного срока, так и после его окончания в течение всего периода эксплуатации.</li>
                    <li>Осуществляем ремонт оборудования других производителей.</li>
                </ul>
                <p>Гарантия не распространяется на повреждения, возникшие в результате естественного износа, плохого ухода, неправильного использования или небрежного обращения, а также являющиеся следствием несанкционированного вмешательства в устройство лиц, не имеющих специального разрешения на проведение ремонта. Для определения характера повреждений и/или причин отказа проводится техническая экспертиза, по результатам которой принимается решение о замене /ремонте изделия. Изделие принимается на экспертизу только при наличии паспорта с отметкой о дате продажи и штампом организации-продавца.</p>


                <label>Сервис и работа с рекламациями</label>

                <div>
                    <strong>Наши клиенты никогда не останутся наедине со своими проблемами. Мы заинтересованы в улучшении КАЧЕСТВА ОБСЛУЖИВАНИЯ. Ваши отзывы и идеи помогают нам стать лучше!</strong>
                    <p>Вы всегда можете обратиться к нам с вопросами, претензиями и пожеланиями по качеству работы филиалов компании, менеджеров и сотрудников других структурных подразделений.</p>
                    <p>Регламентированный порядок работ с рекламациями от клиентов позволяет оперативно решать возникающие проблемы в конкретно установленные сроки.</p>
                </div>

                <hr />

                <p>Для обращения в службу сервиса вышлите заполненный бланк рекламации на указанную электронную почту. Бланк нужно скачать на Ваш компьютер и заполнить в электронном виде (например, при помощи редактора Pdf Xchange Viewer) или распечатать, заполнить от руки и скинуть скан. Уточняющие вопросы можно задать по телефону сервисной службы.</p>

                <div>
                    <p>Электронная почта: {HtmlReactParser(MAIL)}</p>
                    
                    <p>Телефон сервисной службы: {HtmlReactParser(PHONE_ONE)}</p>

                    <p>
                        <a href="/upload/blank-reklamatsii.pdf" target="_blank">Бланк рекламации (pdf, 43 КБ)</a>
                    </p>

                </div>
            </div>
        </InfoPage>
    )
}

export default Warranty
