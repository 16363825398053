import React from 'react'


export default function DeliveryPek() {
    return (
        <div
            className="mt-3 mb-3"
        >
            В разработке...
        </div>
    )
}
