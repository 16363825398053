
import { useEffect, useState } from "react"

import { addedCarousel, deleteCarousel, getAllCarousels } from "../../../http/carouselAPI"
import Loading from "../../../components/Loading"
import { Button, Input } from "../../../components/myBootstrap"

import './CarouselService.css'


const CarouselService = () => {

    const [ carousels, setCarousels ] = useState(null)
    const [ loading, setLoading ] = useState(false)
    const [ file, setFile ] = useState(null)

    useEffect(() => {
        setLoading(true)
        getAllCarousels()
            .then(data => {
                setCarousels(data)
            })
            .finally(_ => {
                setLoading(false)
            })
    },[])

    const onClickDeleteCarousel = (name) => {
        let yes = confirm("Уверены что хотите удалить карусель?")
        if (yes) {
            setLoading(true)
            deleteCarousel(name)
            .then(data => {
                if (data) {
                    alert("Карусель удалена!")
                    getAllCarousels()
                        .then(catalogs => {
                            setCarousels(catalogs)
                        })
                        .finally(_ => {
                            setLoading(false)
                        })
                }else setLoading(false)
            })
        }
    }

    const onClickAddedCarousel = () => {
        
        const formData = new FormData()
        if (file) {
            formData.append("carousel", file)
            setLoading(true)
            addedCarousel(formData)
            .then(data => {
                if (data) {
                    alert("Карусель добавлена!")
                    setFile(null)
                    getAllCarousels()
                        .then(carousels => {
                            setCarousels(carousels)
                        })
                        .finally(_ => {
                            setLoading(false)
                        }) 
                }else setLoading(false)
            })
        }
    }

    if (loading) return <Loading />

    return (
        <div
            className="CarouselService"
        >
            <span>Файл карусели</span>
            <Input 
                type="file"
                className="LeidTogiParserPage_box_file" 
                onChange={(e) => setFile(e.target.files[0])} 
                placeholder="Выберите файл" 
            />
            <Button 
                variant="outline-success"
                disabled={ ! file && true}
                onClick={onClickAddedCarousel}
            >
                Добавить карусель
            </Button>

            <hr />

            {carousels && Array.isArray(carousels) && 
            carousels.map(item => {
                return (
                    <div
                        key={item+"admin"}
                        className="CarouselService_one"
                    >
                        {item}
                        &nbsp;
                        <Button 
                            variant="outline-danger"
                            onClick={() => onClickDeleteCarousel(item)}
                        >
                            Удалить
                        </Button>
                    </div>
                )
            })
            }
        </div>
    )
}

export default CarouselService 
