
import { $host, $authHost } from './index'


export const getAllCarousels = async () => {
    const { data } = await $host.get('api/carousel') 
    return data  
}

export const addedCarousel = async (body) => {
    const { data } = await $authHost.post('api/carousel', body) 
    return data  
}

export const deleteCarousel = async (name) => {
    const { data } = await $authHost.delete('api/carousel/' + name) 
    return data  
}
