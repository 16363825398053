
import React, { useState, useEffect } from 'react'
import { Carousel as CarouselBootstrap, Container } from 'react-bootstrap'

import { API_URL, URL } from '../../../utils/consts'
import Loading from '../../Loading'

import './Carousel.css'
import { getAllCarousels } from '../../../http/carouselAPI'


const Carousel = (props) => {

    const [index, setIndex] = useState(0)
    const [ loading, setLoading ] = useState(false)
    const [ carousels, setCarousels ] = useState(null)

    
    useEffect(() => {
        setLoading(true)
        getAllCarousels()
            .then(data => {
                setCarousels(data)
                setLoading(false)
            })
    },[])

    
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex)
    }

    
    if (loading) return <Loading />

    if ( ! carousels || ! carousels[0] ) return null
    
    return (
        <CarouselBootstrap 
            activeIndex={index} 
            onSelect={handleSelect} 
            className={props?.padding === "bottom" ? "Carousel Carousel_padding_bottom" : "Carousel Carousel_padding_top"}
            variant="light"
        >
            {carousels.map((carousel, idx) => {
                return (
                    <CarouselBootstrap.Item key={"Carousel_image_" + idx}>
                        <Container
                            className="CarouselContainer"
                        >
                            <img
                                className="CarouselImg"
                                src={API_URL + "carousel/" + carousel}
                                alt="First slide"
                            />
                        </Container>
                    </CarouselBootstrap.Item>
                )
            })}
        </CarouselBootstrap>
    )

}

export default Carousel
