//

export const Turkish = {
    // registration page
    registr_title: 'Kayıt',
    registr_surname: 'Soyadı:',
    registr_surname_placeholder: 'Soyadınızı girin',
    registr_name: 'Ad:',
    registr_name_placeholder: 'Bir ad girin',
    registr_patronymic: 'Göbek adı: (varsa)',
    registr_patronymic_placeholder: 'Orta adınızı girin',
    registr_telephone: 'Telefon:',
    registr_telephone_placeholder: 'Telefon numarasını girin',
    registr_postal_address: 'Posta adresi:',
    registr_address: 'Adres:',
    registr_address_placeholder: 'Adresi girin',
    registr_email: 'E-posta:',
    registr_email_placeholder: 'E-postayı girin',
    registr_password: 'Parola:',
    registr_password_placeholder: 'Şifreyi girin',
    registr_legal_entity_title: 'Tüzel kişinin kaydı',
    registr_legal_entity: 'Tüzelkişi',
    registr_company_name: 'Şirket adı:',
    registr_company_name_placeholder: 'Şirket adını girin',
    registr_itn: 'INN (T. C. Kimlik No):',
    registr_itn_placeholder: `Kimlik No yazın`,
    registr_kpp: 'KPP (Kayıt nedeninin kodu):',
    registr_kpp_placeholder: 'Kayıt nedeninin kodunu girin',
    registr_ogrn: 'OGRN (Ana devlet kayıt numarası):',
    registr_ogrn_placeholder: 'Birincil devlet kayıt numarasını girin',
    registr_okved: 'OKVED (Ekonomik faaliyet türlerinin Tüm Rusya sınıflandırıcısı):',
    registr_okved_placeholder: 'OKVED yazın',
    registr_legal_address: 'Yasal adres:',
    registr_legal_address_placeholder: 'Yasal adresi girin',
    registr_bank_name: 'Bankanın adı:',
    registr_bank_name_placeholder: 'Bankanın adını girin',
    registr_bik: 'BIC (Banka kimlik kodu):',
    registr_bik_placeholder: 'BIC girin',
    registr_cor_accaunt: 'Muhabir hesabı:',
    registr_cor_accaunt_placeholder: 'Muhabir hesabını girin',
    registr_payment_accaunt: 'Çek hesabı:',
    registr_payment_accaunt_placeholder: 'Çek hesabını girin',
    registr_post: 'Görev:',
    registr_post_placeholder: 'Pozisyonu girin',
    registr_have_account: 'Hesabınız var mı?',
    registr_enter: 'İçeri girin!',
    // login page
    login_title: 'Yetkilendirme',
    login_email: 'E-posta veya Giriş:',
    login_password: 'Parola:',
    login_forgot_password: 'Şifreyi unuttunuz mu?!',
    login_confirm: 'Onaylamak için içeri girin.',
    login_no_accaunt: 'Hesap yok mu?',
    login_register: 'Kayıt olun!',
    login_enter: 'Girmek',
    // consts_name
    consts_name: `LLC "LEİDTOGİ"`,
    consts_sity: 'G. Kursk',
    consts_address: 'Kursk, ul.Solovinaya, d.51, ofisten.25',
    consts_address_full: '305044, Kursk, ul.Solovinaya, d.51, ofisten.25',    
    consts_address_full_moscow: '125438, Moscow, 2nd Likhachevsky lane, 7',
    consts_time_to_work: 'Pazartesi - Cuma: 09:00 - 18:00',
    consts_time_to_weekend: 'Cts - Paz: gün sonu',
    // header
    header_slogan: 'Bizimle inşa edin, zamandan tasarruf edin',
    header_ap: 'YP', // Yönetici Paneli
    header_mp: 'IP', // Işletmecisinden Paneli
    header_lk: 'KH', // Kişisel Hesap
    header_pers_acc: 'Kişisel Hesap',
    header_exit: 'Çıkış',
    header_auth: 'Yetkilendirme',
    // pages
    page_shop: 'Ürün',
    page_dealer: 'Bayilere',
    page_payment: 'Ödeme',
    page_specials: 'İndirimler',
    page_delivery: 'Teslim',
    page_support: 'Tek.destek',
    page_news: 'Haber',
    page_catalogs: 'Dizinler',
    page_contacts: 'Temaslar',
    page_about_us: 'Şirket hakkında',
    page_returns_policy: 'İade Koşulları',
    page_warranty: 'Garanti ve Servis',
    page_privacy_policy: 'Gizlilik Politikası',
    page_terms_of_use: 'Kullanıcı Sözleşmesi',
    page_presentation: 'Sunu',
    // footer
    footer_inform: 'Bilgi',
    footer_web: 'Sosyal medyadayız',
    footer_web_tg: `Biz Telegram'dayız`,
    footer_web_vk: `Biz Vkontakte'deyiz`,
    footer_contacts: 'Temaslar',
    footer_copyright: '© LeidTogi LLC',
}
