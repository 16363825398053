import React from 'react'
import InfoPage from './InfoPage'
import './ReturnsPolicy.css'


const ReturnsPolicy = () => {
    return (
        <InfoPage>
            <div className="ReturnsPolicy">
                <header>Условия возврата!</header>
                {/* <label></label> */}
                
                <p>При оплате картами возврат наличными денежными средствами не допускается. Порядок возврата регулируется правилами международных платежных систем.</p>
                <p>Процедура возврата товара регламентируется статьей 26.1 федерального закона «О защите прав потребителей».</p>

                <p>- потребитель вправе отказаться от товара в любое время до его передачи, а после передачи товара - в течение семи дней;</p>
                <p>- возврат товара надлежащего качества возможен в случае, если сохранены его товарный вид, потребительские свойства, а также документ, подтверждающий факт и условия покупки указанного товара;</p>
                <p>- потребитель не вправе отказаться от товара надлежащего качества, имеющего индивидуально-определенные свойства, если указанный товар может быть использован исключительно приобретающим его человеком;</p>
                <p>- при отказе потребителя от товара продавец должен возвратить ему денежную сумму, уплаченную потребителем по договору, за исключением расходов продавца на доставку от потребителя возвращенного товара, не позднее чем через десять дней со дня предъявления потребителем соответствующего требования;</p>

                <p>Для возврата денежных средств на банковскую карту необходимо заполнить «Заявление о возврате денежных средств», которое высылается по требованию компанией на электронный адрес и оправить его вместе с приложением копии паспорта по адресу info@leidtogi.ru</p>
                <p>Возврат денежных средств будет осуществлен на банковскую карту в течение 21 (двадцати одного) рабочего дня со дня получения «Заявление о возврате денежных средств» Компанией.</p>
                <p>Для возврата денежных средств по операциям проведенными с ошибками необходимо обратиться с письменным заявлением и приложением копии паспорта и чеков/квитанций, подтверждающих ошибочное списание. Данное заявление необходимо направить по адресу info@leidtogi.ru</p>
                <p>Сумма возврата будет равняться сумме покупки. Срок рассмотрения Заявления и возврата денежных средств начинает исчисляться с момента получения Компанией Заявления и рассчитывается в рабочих днях без учета праздников/выходных дней.</p>
            </div>
        </InfoPage>
    )
}

export default ReturnsPolicy
