import React from 'react'

import { NavLink } from '../../components/myBootstrap'
import { TERMS_OF_USE_ROUTE } from '../../utils/consts'
import InfoPage from './InfoPage'
import './TermsOfUse.css'


const TermsOfUse = () => {
    return (
        <InfoPage>
            <div className="TermsOfUse">

                <header>Пользовательское соглашение сайта leidtogi.ru</header>

                <p>Настоящий документ "Пользовательское соглашение" представляет собой предложение ООО «Леидтоги» (далее — «Администрация»), заключить договор на изложенных ниже условиях Соглашения.</p>
                
                <label>1. Общие условия пользовательского соглашения</label>

                <p>1.1. В настоящем документе и вытекающих или связанным с ним отношениях Сторон применяются следующие термины и определения:</p>
                <p>а) Платформа — программно-аппаратные средства, интегрированные с Сайтом Администрации;</p>
                <p>б) Пользователь — дееспособное физическое лицо, присоединившееся к настоящему Соглашению в собственном интересе либо выступающее от имени и в интересах представляемого им юридического лица.</p>
                <p>в) Сайт Администрации/ Сайт — интернет-сайты, размещенные в домене http://leidtogi.ru и его поддоменах.</p>
                <p>г) Сервис — комплекс услуг и лицензия, предоставляемые Пользователю с использованием Платформы.</p>
                <p>д) Соглашение - настоящее соглашение со всеми дополнениями и изменениями.</p>
                <p>e) Контент – любые информационные материалы, включая текстовые, графические, аудиовизуальные и прочие материалы, к которым можно получить доступ с использованием Сервиса.</p>
                <p>1.2. Использование вами Сервиса любым способом и в любой форме в пределах его объявленных функциональных возможностей, включая:</p>
                <ul>
                    <li>просмотр размещенных на Сайте материалов,</li>
                    <li>регистрация и/или авторизация на Сайте,</li>
                    <li>обращение в службу поддержки Сайта по реквизитам, размещенным на Сайте,</li>
                    <li>размещение или отображение на Сайте любых материалов, включая но не ограничиваясь такими как: тексты, гипертекстовые ссылки, изображения, аудио и видео- файлы, сведения и/или иная информация,</li>
                    <li>иное использование Сервиса,</li>
                </ul>
                <p>создает договор на условиях настоящего Соглашения в соответствии с положениями ст.437 и 438 Гражданского кодекса Российской Федерации.</p>
                <p>1.3. Воспользовавшись любой из указанных выше возможностей по использованию Сервиса вы подтверждаете, что:</p>
                <p>а) Ознакомились с условиями настоящего Соглашения в полном объеме до начала использования Сервиса.</p>
                <p>б) Принимаете все условия настоящего Соглашения в полном объеме без каких-либо изъятий и ограничений с вашей стороны и обязуетесь их соблюдать или прекратить использование Сервиса. Если вы не согласны с условиями настоящего Соглашения или не имеете права на заключение договора на их основе, вам следует незамедлительно прекратить любое использование Сервиса.</p>
                <p>в) Соглашение (в том числе любая из его частей) может быть изменено Администрацией без какого-либо специального уведомления. Новая редакция Соглашения вступает в силу с момента ее размещения на Сайте Администрации либо доведения до сведения Пользователя в иной удобной форме, если иное не предусмотрено новой редакцией Соглашения.</p>
                
                <label>2. Условия использования сайта</label>

                <p>2.1. Использование функциональных возможностей Сервиса допускается только после прохождения Пользователем регистрации и авторизации на Сайте в соответствии с установленной Администрацией процедурой.</p>
                <p>2.2. Технические, организационные и коммерческие условия использования Сервиса, в том числе его функциональных возможностей доводятся до сведения Пользователей путем отдельного размещения на Сайте или путем нотификации Пользователей.</p>
                <p>2.3. Выбранные Пользователем логин и пароль являются необходимой и достаточной информацией для доступа Пользователя на Сайт. Пользователь не имеет права передавать свои логин и пароль третьим лицам, несет полную ответственность за их сохранность, самостоятельно выбирая способ их хранения.</p>
                
                <label>3. Ограничения</label>

                <p>Соглашаясь с условиями настоящего Пользовательского соглашения, вы понимаете и признаете, что:</p>
                
                <p>3.1. Поскольку Сервис находится на стадии постоянного дополнения и обновления новых функциональных возможностей, форма и характер предоставляемых услуг могут время от времени меняться без предварительного уведомления Пользователя. Правообладатель вправе по собственному усмотрению прекратить (временно или окончательно) предоставление услуг (или каких-либо отдельных функций в рамках услуг) всем Пользователям вообще или вам, в частности, без вашего предварительного уведомления.</p>
                <p>3.2. Пользователь не имеет права самостоятельно или с привлечением третьих лиц:</p>
                <ul>
                    <li>копировать (воспроизводить) в любой форме и способом входящие в состав Сервиса Правообладателя программы для ЭВМ и базы данных, включая любые их элементы и Контент, без получения предварительного письменного согласия их владельца;</li>
                    <li>вскрывать технологию, эмулировать, декомпилировать, дизассемблировать, дешифровать, и производить иные аналогичные действия с Сервисом;</li>
                    <li>создавать программные продукты и/или сервисы с использованием Сервиса без получения предварительного разрешения Правообладателя.</li>
                </ul>
                <p>3.3. При обнаружении ошибок в работе Сервиса или в размещенном на нем Контенте сообщите об этом Правообладателю по адресу, указанному в реквизитах или отдельно на Сайте для службы поддержки.</p>
                <p>3.4. При любых обстоятельствах ответственность Правообладателя ограничена 1 000 (Одной тысячей) рублей и возлагается на него исключительно при наличии в его действиях вины.</p>

                <label>4. Уведомления</label>

                <p>4.1. Пользователь соглашается получать от Правообладателя на электронный адрес и/или абонентский номер телефона, указанный вами при работе с Сервисом, информационные электронные сообщения (далее — «нотификаторы»).</p>
                <p>4.2. Правообладатель вправе использовать нотификаторы для информирования Пользователя об изменениях и новых возможностях Сервиса, об изменении Соглашения или указанных в нем Обязательных документов, а также для рассылок информационного или рекламного характера.</p>

                <label>5. Прочие условия</label>
                
                <p> 5.1. Настоящее Пользовательское соглашение, порядок его заключения и исполнения, а также вопросы, не урегулированные настоящим Соглашением, регулируются действующим законодательством Российской Федерации.</p>
                <p>5.2. Все споры по Соглашению или в связи с ним подлежат рассмотрению в суде по месту нахождения Правообладателя в соответствии с действующим процессуальным правом Российской Федерации.</p>
                <p>5.3. Настоящее Соглашение может быть изменено или прекращено Правообладателем в одностороннем порядке без предварительного уведомления Пользователя и без выплаты какой-либо компенсации в связи с этим.</p>
                <p>5.4. Действующая редакция настоящего Соглашения размещена на Сайте Правообладателя и доступна в сети Интернет по адресу <NavLink to={TERMS_OF_USE_ROUTE}>leidtogi.ru/terms_of_use</NavLink></p>

                {/* <p>5.6. Реквизиты Правообладателя:</p>
                <ul>
                    <li>ФИО</li>
                    <li>ОГРН (ИП):</li>
                    <li>ИНН:</li>
                    <li>Адрес:</li>
                    <li>Email:</li>
                </ul> */}

                <p>Редакция от 3 ноября 2021г.</p>
            </div>
        </InfoPage>
    )
}

export default TermsOfUse
