import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import './DeletePage.css'


const Delete = () => {

    let navigate = useNavigate()
    let location = useLocation()

    // navigate.block()
    // history.replace("specials")
    // history.goBack()
    // history.push("/")

    // console.log(location)
    // location.pathname = "ggg"

    return (
        <div
            className="Delete"
        >
           <p>
                Сайт удалён!
            </p>
        </div>
    )
}

export default Delete
