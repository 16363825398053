import React from 'react'
import HtmlReactParser from 'html-react-parser'

import { PHONE_ONE } from '../../utils/consts'
import InfoPage from './InfoPage'
import './Payment.css'


const Payment = () => {
    return (
        <InfoPage>
            <div className="Payment">
                <header>Способы оплаты и правила оплаты по банковским картам!</header>
                
                <ul>
                    <li>
                        <label>Безналичный расчет</label>
                        <p>Оплата по безналичному расчету проводится на основании счета. Счет можно получить от менеджеров компании или через личный кабинет на сайте после оформления и подтверждения заказа.</p>
                    </li>
                    <li>
                        <label>Оплата наличными</label>
                        <p>Наличный расчет производится в офисах компании во всех городах присутствия.</p>
                    </li>
                    <li>
                        <label>Оплата в режиме онлайн</label>
                        <p>Оплата проводится с помощью банковских карт через платежный агрегатор на нашем сайте. Для выбора оплаты товара с помощью банковской карты на соответствующей странице необходимо нажать кнопку «Оплатить банковской картой».</p>
                    </li>
                </ul>

                <p>Оплата банковскими картами осуществляется через АО «АЛЬФА-БАНК».</p>

                <div className="PaymentImage">
                    <img src="images/bank/AlfaBank.png" alt="Изображение бановской карты" />
                </div>

                {/* <br /> */}

                <p>К оплате принимаются карты VISA, MasterCard, Платежная система «Мир».</p>

                <div className="PaymentImage">
                    <img src="images/bank/Visa.jpg" alt="Изображение бановской карты" />
                    <img src="images/bank/MasterCard.jpg" alt="Изображение бановской карты" />
                    <img src="images/bank/Mir.jpg" alt="Изображение бановской карты" />
                </div>

                <div>
                    <p>Услуга оплаты через интернет осуществляется в соответствии с Правилами международных платежных систем Visa, MasterCard и Платежной системы МИР на принципах соблюдения конфиденциальности и безопасности совершения платежа, для чего используются самые современные методы проверки, шифрования и передачи данных по закрытым каналам связи. Ввод данных банковской карты осуществляется на защищенной платежной странице АО «АЛЬФА-БАНК».</p>
                    <p>На странице для ввода данных банковской карты потребуется ввести данные банковской карты: номер карты, имя владельца карты, срок действия карты, трёхзначный код безопасности (CVV2 для VISA, CVC2 для MasterCard, Код Дополнительной Идентификации для МИР).</p>
                    <p>Все необходимые данные пропечатаны на самой карте.</p>
                    <p>Трёхзначный код безопасности — это три цифры, находящиеся на обратной стороне карты.</p>
                    <p>Далее вы будете перенаправлены на страницу Вашего банка для ввода кода безопасности, который придет к Вам в СМС.</p>
                    <p>Если код безопасности к Вам не пришел, то следует обратиться в банк выдавший Вам карту.</p>
                </div>

                <div className="PaymentImage">
                    <img src="images/bank/MasterCardSecureCode.jpg" alt="Изображение бановской карты" />
                    <img src="images/bank/VerifiedByVisa.jpg" alt="Изображение бановской карты" />
                    <img src="images/bank/MirAccept.jpg" alt="Изображение бановской карты" />
                </div>


                <div>
                    <ul>
                        <li>
                            <label>Случаи отказа в совершении платежа:</label>
                            <p>- банковская карта не предназначена для совершения платежей через интернет, о чем можно узнать, обратившись в Ваш Банк;</p>
                            <p>- недостаточно средств для оплаты на банковской карте. Подробнее о наличии средств на банковской карте Вы можете узнать, обратившись в банк, выпустивший банковскую карту;</p>
                            <p>- данные банковской карты введены неверно;</p>
                            <p>- истек срок действия банковской карты. Срок действия карты, как правило, указан на лицевой стороне карты (это месяц и год, до которого действительна карта). Подробнее о сроке действия карты Вы можете узнать, обратившись в банк, выпустивший банковскую карту;</p>
                        </li>
                        <li>
                            <p>По вопросам оплаты с помощью банковской карты и иным вопросам, связанным с работой сайта, Вы можете обращаться по телефону: {HtmlReactParser(PHONE_ONE)}.</p>
                        </li>
                        <li>
                            <p>Предоставляемая вами персональная информация (имя, адрес, телефон, e-mail, номер банковской карты) является конфиденциальной и не подлежит разглашению. Данные вашей кредитной карты передаются только в зашифрованном виде и не сохраняются на нашем Web-сервере.</p>
                        </li>
                    </ul>
                </div>

            </div>
        </InfoPage>
    )
}

export default Payment
